import React from "react";
import Navigation from "./Navigation";
import "./Header.scss";
import { Link } from "gatsby";
import Logo from "./../images/icon.png";
import ResponsiveNav from "./Responsive-nav";

const Header = () => {
  return (
    <header>
      <div className="container">
        <Link className="logo" to="/">
          <img src={Logo} alt="logo" />
          <span>ORANGE</span>
        </Link>
        <Navigation />
        <ResponsiveNav />
      </div>
    </header>
  );
};

export default Header;
