import React from "react";
import Topbar from "./Topbar";
import Header from "./Header";
import Footer from "./Footer";

import "./../global/global.scss";
import "./Layout.scss";

const Layout = (props) => {
  return (
    <div className="global-wrapper">
      <Topbar />
      <Header />
      <main>{props.children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
