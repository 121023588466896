import React from "react";
import { Link } from "gatsby";
import "./Navigation.scss";

const Navigation = () => {
  return (
    <nav className="global-nav">
      <Link
        className="global-nav-link"
        activeClassName="global-nav-link-active"
        to="/"
      >
        ホーム
      </Link>
      <Link
        className="global-nav-link"
        activeClassName="global-nav-link-active"
        to="/sub"
      >
        会社概要<span class="material-icons">expand_more</span>
        <div className="subnav">
          <Link to="/sub">サブメニュー</Link>
          <Link to="/sub">サブメニュー</Link>
          <Link to="/sub">サブメニュー</Link>
        </div>
      </Link>
      <Link
        className="global-nav-link"
        activeClassName="global-nav-link-active"
        to="/sub"
      >
        サービス<span class="material-icons">expand_more</span>
        <div className="subnav">
          <Link to="/sub">サブメニュー</Link>
          <Link to="/sub">サブメニュー</Link>
          <Link to="/sub">サブメニュー</Link>
        </div>
      </Link>
      <Link
        className="global-nav-link"
        activeClassName="global-nav-link-active"
        to="/sub"
      >
        採用情報
      </Link>
      <Link
        className="global-nav-link"
        activeClassName="global-nav-link-active"
        to="/sub"
      >
        お問い合わせ
      </Link>
    </nav>
  );
};

export default Navigation;
