import React from "react";
import "./Topbar.scss";

const Topbar = () => {
  return (
    <div className="topbar">
      <div className="container">
        <div className="social">
          <span className="fab fa-twitter item"></span>
          <span className="fab fa-google-plus-g item"></span>
        </div>
        <div className="contact">
          <div className="item">
            <span class="material-icons icon">phone</span>
            <span>XX-XXXX-XXXX</span>
          </div>
          <div className="item">
            <span class="material-icons icon">email</span>
            <span>XXX@XXXX.XX</span>
          </div>
          <div className="item">
            <span class="material-icons icon">schedule</span>
            <span>09:00 - 18:00（月 - 金）</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
