import React from "react";
import "./Footer.scss";
import { Link } from "gatsby";
import Logo from "./../images/icon.png";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="profile">
          <Link to="/" className="profile-logo">
            <img src={Logo} alt="logo" />
            <span>DL株式会社</span>
          </Link>
          <ul className="profile-contact">
            <li>TEL</li>
            <li>XX-XXXX-XXXX</li>
            <li>MAIL</li>
            <li>XXXX@XXXX.XXX</li>
            <li>ACCESS</li>
            <li>XX県XX市XXX XX-XX-X</li>
          </ul>
          <Link to="/" className="profile-btn">
            お問い合わせフォーム
          </Link>
        </div>
        <div className="news">
          <span className="news-heading">お知らせ</span>
          <ul className="news-list">
            <li>2021年7月30日</li>
            <li>
              <Link to="/">
                何しろ今に力説士もたといこんな比較ないなくらいを立ち入りがいうのは腐敗あれましうので、そうには思うないでますん。
              </Link>
            </li>
            <li>2021年7月30日</li>
            <li>
              <Link to="/">
                それも昔もうその講演方というののために抜いでで。
              </Link>
            </li>
            <li>2021年7月30日</li>
            <li>
              <Link to="/">慾が遂げよな事は一々十月がいったいたらしいた。</Link>
            </li>
          </ul>
          <Link to="/" className="news-btn">
            お知らせ一覧を見る
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
