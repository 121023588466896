import React from "react";
import { Link } from "gatsby";
import "./Responsive-nav.scss";

const ResponsiveNav = () => {
  const NavToggle = () => {
    const target = document.getElementById("navToggle");
    target.classList.toggle("nav-open");
  };

  return (
    <div className="responsive-nav">
      {/* アイコン */}
      <button className="responsive-nav-icon" onClick={NavToggle}>
        <span class="material-icons">menu</span>
      </button>
      {/* ナビゲーション */}
      <nav className="responsive-nav-toggle" id="navToggle">
        <button className="icon" onClick={NavToggle}>
          <span class="material-icons">close</span>
        </button>
        <Link to="/" activeClassName="active">
          ホーム
        </Link>
        <Link to="/sub" activeClassName="active">
          会社概要
        </Link>
        <div className="subnav" activeClassName="active">
          <Link to="/sub" activeClassName="active">
            サブメニュー
          </Link>
          <Link to="/sub" activeClassName="active">
            サブメニュー
          </Link>
          <Link to="/sub" activeClassName="active">
            サブメニュー
          </Link>
        </div>
        <Link to="/sub" activeClassName="active">
          サービス
        </Link>
        <div className="subnav">
          <Link to="/sub" activeClassName="active">
            サブメニュー
          </Link>
          <Link to="/sub" activeClassName="active">
            サブメニュー
          </Link>
          <Link to="/sub" activeClassName="active">
            サブメニュー
          </Link>
        </div>
        <Link to="/sub" activeClassName="active">
          採用情報
        </Link>
        <Link to="/sub" activeClassName="active">
          お問い合わせ
        </Link>
      </nav>
    </div>
  );
};

export default ResponsiveNav;
